<template>
<header class="header-top">
    <!-- <nav class="d-flex justify-content-between">
        <div class="p-3">
            <a @click="$router.push({ name: 'Lottery'});" ><img class="svg dark pointer" style="max-height: 30px;" src="/images/logo_head_center.png" alt="svg"></a>
        </div>
        <div class="p-3"><h2>{{getNavName}}</h2></div>
        <div class="p-3">
            <a :class="`local pointer ${getLocal=='en'?'active':''}`" @click="setLocal('en')" >EN</a><span>|</span><a :class="`local pointer ${getLocal=='th'?'active':''}`" @click="setLocal('th')">TH</a>
            <a @click="BTNlogout" class="pointer ml-2">
                <span data-feather="log-out"></span> Sign Out
            </a>
            <a @click="gocart"  class="ml-2 position-relative">
                <svg width="30" height="30" viewBox="0 0 78 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.42859 3.64258H16.2857L24.9 46.6819C25.1939 48.1617 25.999 49.491 27.1743 50.4371C28.3495 51.3832 29.8201 51.8858 31.3286 51.8569H62.5714C64.0799 51.8858 65.5505 51.3832 66.7258 50.4371C67.901 49.491 68.7061 48.1617 69 46.6819L74.1429 19.714H19.5M32.3572 67.9283C32.3572 69.7035 30.9181 71.1426 29.1429 71.1426C27.3677 71.1426 25.9286 69.7035 25.9286 67.9283C25.9286 66.1531 27.3677 64.714 29.1429 64.714C30.9181 64.714 32.3572 66.1531 32.3572 67.9283ZM67.7143 67.9283C67.7143 69.7035 66.2752 71.1426 64.5 71.1426C62.7248 71.1426 61.2857 69.7035 61.2857 67.9283C61.2857 66.1531 62.7248 64.714 64.5 64.714C66.2752 64.714 67.7143 66.1531 67.7143 67.9283Z" stroke="#272B41" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span v-if="rottoTotalQty>0" class="delete-x">{{rottoTotalQty}}</span>
            </a>
            
        </div>
    </nav> -->
    <nav class="navbar navbar-light">
        <div class="navbar-left py-2">
            <!-- <ul class="navbar-right__menu">
                <li class="nav-author">
                    <div class="dropdown-custom">
                        <a @mouseenter="getCredit()" @click="$router.push({ name: 'Lottery'});"  class="nav-item-toggle ">
                            <img class="svg dark pointer" style="max-height: 100px;" src="/images/numchok.png" alt="svg">
                        </a>
                        
                        <div class="dropdown-wrapper">
                            <div class="nav-author__info">
                                <div class="author-img">
                                    <img class="img-nav-profile rounded-circle"  src="/images/numchok.png" alt="" >
                                </div>
                                <div>
                                    <h6>Credit: {{credit}} บาท</h6>
       
                                </div>
                            </div>
                            <div class="nav-author__options">
                                <ul>
                                    <li>
                                        <a @click="$router.push({ name: 'History'});" >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>History
                                        </a>
                                    </li>
                                </ul>
                                <a @click="BTNlogout" class="nav-author__signout">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                        Sign Out
                                </a>

                                

                            </div>
                        </div>
        
                    </div>
                </li>

            </ul> -->
            <div class="row pl-2 justify-content-start">
                <div class="">
                    <p class="profile m-0" >Credit: {{credit?credit:0}} บาท</p>
                    <p class="profile m-0">{{member.firstName}} {{member.lastName}}</p>
                    <a @click="BTNlogout" class="nav-author__signout">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                        Sign Out
                                </a>
                </div>
                
                <button @click="getPayment" class="btn-lotto bg-yellow mx-1">เติมเงิน</button>
                <button @click="$router.push({ name: 'History'});" class="btn-lotto bg-yellow mx-1">ประวัติ</button>
                
            </div>
    
        </div>

        <div class="navbar-right ">
            <a v-if="currentRouteName=='Lottery'" @click="gocart" class="nav-item-toggle position-relative">
                <svg width="30" height="30" viewBox="0 0 78 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.42859 3.64258H16.2857L24.9 46.6819C25.1939 48.1617 25.999 49.491 27.1743 50.4371C28.3495 51.3832 29.8201 51.8858 31.3286 51.8569H62.5714C64.0799 51.8858 65.5505 51.3832 66.7258 50.4371C67.901 49.491 68.7061 48.1617 69 46.6819L74.1429 19.714H19.5M32.3572 67.9283C32.3572 69.7035 30.9181 71.1426 29.1429 71.1426C27.3677 71.1426 25.9286 69.7035 25.9286 67.9283C25.9286 66.1531 27.3677 64.714 29.1429 64.714C30.9181 64.714 32.3572 66.1531 32.3572 67.9283ZM67.7143 67.9283C67.7143 69.7035 66.2752 71.1426 64.5 71.1426C62.7248 71.1426 61.2857 69.7035 61.2857 67.9283C61.2857 66.1531 62.7248 64.714 64.5 64.714C66.2752 64.714 67.7143 66.1531 67.7143 67.9283Z" stroke="#272B41" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span v-if="rottoTotalQty>0" class="delete-x">{{rottoTotalQty}}</span>
            </a>
            <a v-else  @click="$router.push({ name: 'Lottery'});" class="nav-item-toggle position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
            </a>
          
        </div>
        
    </nav>
     <Modal v-if="showModal" :maxWidth="200" @close="showModal = false;getCredit();">
        <template v-slot:body>
            <div class="row"  >
                <div class="col-12 p-3" >
                    <h3 v-if="qrcode" class="text-center">แสกนเพื่อเติมเงิน</h3>
                    <img v-if="qrcode" class="w-100" :src="qrcode" alt="">
                    <h3 v-else class="text-center">กำลังโหลด...</h3>
                </div>
            </div>
        </template>
      </Modal>
</header>

</template>

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import Modal from '@/components/Modal.vue'
export default {
  name: 'Navbar',
  components: {
   
    Modal
  },
  data: function () {
    return {
        credit:'',
        member:{
            partnerId: null,
            memberId: null,
            firstName: null,
            lastName: null,
            phoneNo: null
        },
        showModal:false,
        qrcode:''
      }
    },
  mounted() {
    this.getCredit()
  },

  methods: {
    BTNlogout(){
      this.$store.dispatch('auth/logout')
      this.$router.push('/login');
    },
    toggleAside(){
       this.$store.dispatch('display/setNavName','welcome')
      
    },
    setLocal(para_local){
        this.$store.dispatch('display/setLocal',para_local)
    },
    gocart(){
        try {
            if(this.rottoTotalQty==0){
                throw new Error('กรุณาเพิ่มสินค้าลงตะกร้าก่อนครับ')
            }
            this.$router.push({ name: 'Cart'});
        } catch (error) {
            serviceMain.showErrorAlert(this,error)
        }
    },
    // hoverLogo(){
        
    //     this.getCredit();
    // },
    async getCredit(){
        try {
            let data = {}
            let getAPI = await this.$call_api('get','lotto/api/v1/getCredit',data,'auth');
            console.log(getAPI);
            this.credit = getAPI.data.credit;
            this.member = getAPI.data.member;
        } catch (error) {
            serviceMain.showErrorAlert(this,error)
        }
    },
    async getPayment(){
        try {
            this.showModal=true;
            let data = {}
            let getAPI = await this.$call_api('get','lotto/api/v1/generateScbQrCodePayment',data,'auth');
            this.qrcode = getAPI.data.qrcode;

        } catch (error) {
            serviceMain.showErrorAlert(this,error)
        }
    },
  },
  computed: {
    getLocal:function(){
        return this.$store.getters['display/getLocal']
    },
    getNavName:function(){
        return this.$store.getters['display/getNavName']
    },
    getCART:function(){
      return this.$store.getters['cart/getCART']
    },
    rottoTotalQty:function(){
      return this.getCART.length;
    },
    currentRouteName() {
        return this.$route.name;
    }

  },
}
</script>

<style scoped lang="scss">
.local.active{
    color: #FFC700;
}
.icon-shadow{
    -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
}
.delete-x{
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 12px;
    background-color: red;
    color: white;
    padding: 0px 3px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 30px;
}
li {
    list-style-type: none;
}
.dropdown-wrapper {
    position: absolute;
    left: 0;
    top: 54px;
    min-width: 360px;
    transition: all 0.3s ease;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    background: #fff;
    box-shadow: 0 5px 30px rgb(146 153 184 / 15%);
    transform: scale(0.7);
    transform-origin: top left;
}
.profile{
    font-size: 0.8em ;
}

</style>
