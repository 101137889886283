<template>
  <Navbar v-if="$store.getters['auth/getloggedIn']" />
  <router-view />
  <Overlay v-if="$store.getters['display/getOverlay']" @close="$store.dispatch('display/closeOverlay')"/>
</template>
<script>

import Navbar from '@/components/Navbar.vue'
import Overlay from '@/components/Overlay.vue'

export default {
  name: 'MainAppLayout',
  components: {
    Navbar,
    Overlay
  },
  mounted() {
 
    this.caches();
  },
  methods: {
    async caches(){
      caches.keys().then(function(names) {
        console.log("In caches")
        console.log(names)
        if(names.length > 0) {
          console.log("Have caches")
          for (let name of names){
            console.log(name)
            caches.delete(name);
            setTimeout(() => {
              window.location.reload(true)
            }, 2000)

          }
        }else{
          console.log("No caches")
        } 
      });

    },
  }
}
</script>

<style scope>

</style>
