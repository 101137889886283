<template>
  <main class="main-content">
    <div class="signUP-admin bg">
      <div class="container-fluid">
        <div class="row min-height">

            
            <div class="col-12 col-lg-8 d-none d-sm-none d-md-none d-lg-block ">
              <div class="row h-100 p-5 justify-content-center align-items-center">
                <img class=" w-100 " src="images/bg1.png" alt="img" />
              </div>
              
            </div>
        
            <div class="col-12 col-lg-4">
              <div class="row">
                <div class=" col-12 login-bg "  >
                  <div class="row h-100 p-md-5 justify-content-center align-items-center">
                    <div class="col-12 bglogin p-md-5 py-5 position-relative">
                      <div class="mx-0 p-0">
                        <div class="card mx-0 mymagin">
                          <div class="card-body">
                            <div class="edit-profile__body">
                              <div class="form-group mb-20">
                                <label for="username" class="font-weight-bold">Username</label>
                                <input type="text" class="form-control" v-model="loginformData.username" id="username" placeholder="Username"/>
                              </div>
                              <div class="form-group mb-15">
                                <label for="password-field" class="font-weight-bold" >password</label >
                                <div class="position-relative">
                                  <input v-on:keyup.enter="loginFN" v-model="loginformData.password" :type="passwordFieldType" class="form-control" name="password" />
                                  <div v-if="passwordFieldType == 'password'" @click="viewpass" class=" fa fa-fw fa-eye-slash text-light fs-16 field-icon  toggle-password2 " ></div>
                                  <div @click="viewpass" class=" fa fa-fw fa-eye text-light fs-16 field-icon toggle-password2 " ></div>
                                </div>
                              </div>
                              <div class="signUp-condition signIn-condition">
                                <div class="checkbox-theme-default custom-checkbox">
                                  <input class="checkbox" type="checkbox" id="check-1" />
                                  <label for="check-1">
                                    <span class="checkbox-text">Remember me</span>
                                  </label>
                                </div>
                                <a href="#" class="forget-pass font-weight-normal" >forget password</a >
                              </div>
                              <div class="d-flex justify-content-center">
                                <button @click="loginFN" class=" btn btn-default btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn btn-renny " > sign in </button>
                              </div>
                            </div>
                          </div>
                        
                        </div>
              
                      <div class="m-0 p-0 img-numchok">
                        <img class="myimg" src="images/numchok.png" alt="img" />
                      </div>
                    </div>
                    <div class="mx-0 p-0 d-block d-sm-block d-md-block d-lg-none">
                        <div class=" mx-0 mymagin">
                          <div class="card-body p-0">
                            <img class=" w-100 " src="images/bg1.png" alt="img" />
                          </div>
                        
                        </div>
              
                      <div class="m-0 p-0 img-numchok">
                        <img class="myimg" src="images/numchok.png" alt="img" />
                      </div>
                    </div>
            
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </main>
</template>

<script>
import * as serviceMain from "@/services/main.service";

export default {
  name: "LoginLayout",
  data: function () {
    return {
      loginformData: {
        username: "",
        password: "",
      },
      passwordFieldType: "password",
    };
  },
  mounted() {
    this.cklogIn();
    this.caches();
  },
  methods: {
    async loginFN() {
      try {
        let data = {
          username: this.loginformData.username,
          password: this.loginformData.password,
        };
        let getAPI = await this.$call_api("post", "lotto/api/v1/login", data);
        this.$store.dispatch("auth/login", getAPI.data.token);
        this.$router.push({ name: "Lottery" });
      } catch (error) {
        console.log("error");
        console.log(error);
        serviceMain.showErrorAlert(this, error);
      }
    },
    async caches(){
      caches.keys().then(function(names) {
        console.log("In caches")
        console.log(names)
        if(names.length > 0) {
          console.log("Have caches")
          for (let name of names){
            console.log(name)
            caches.delete(name);
            setTimeout(() => {
              window.location.reload(true)
            }, 2000)
          }
        }else{
          console.log("No caches")
        } 
      });

    },
    cklogIn: async function () {
      if (this.$store.getters["auth/getloggedIn"]) {
        this.$router.push({ name: "Lottery" });
      }
    },
    viewpass() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
};
</script>
<style scoped lang="scss">
.bg {
  background: linear-gradient(
    117.48deg,
    #f7eaaa 51.09%,
    rgba(126, 104, 23, 0.74) 70.09%
  );
}
.mymagin {
  margin-top: 80px;
}
.bglogin {
  background: linear-gradient(180deg, #aa0a0a 0%, #db9e50 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 39px;
  padding-top: 300px;
  // height: 70vh;
}
.img-numchok {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.myimg {
  width: 100%;
  max-width: 247px;
  min-width: 50px;
}
.img-login {
  height: 45vw;
}

.login-bg {
  min-height: 100vh;
}
.border-login {
  border: 2px lightgray solid;
  border-radius: 8px;
}
.btn-renny {
  background: linear-gradient(180deg, #f4dc00 0%, #ffc700 56.77%);
  border-radius: 4px;
}
.forget-pass {
  color: #2f55a4 !important;
}
</style>