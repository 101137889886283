import { createRouter, createWebHistory } from 'vue-router'

import LoginLayout from '../views/Layout/LoginLayout.vue'
import MainAppLayout from '../views/Layout/MainAppLayout.vue'
import store from '../store/index'
import * as main from '@/services/main.service';
import * as constant from '@/services/constant';
const routes = [
  {
    path: '/',
    component: MainAppLayout,
    children: [
      { path: '/', redirect: '/lottery' },
      {
        path: '/lottery',
        name: 'Lottery',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Lottery.vue" */'../views/Lottery.vue')
      },
      {
        path: '/cart',
        name: 'Cart',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Cart.vue" */'../views/Cart.vue')
      },
      {
        path: '/history',
        name: 'History',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "History.vue" */'../views/History.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginLayout
  },
  
  {
    path: '/:pathMatch(.*)*',
    redirect: "/"
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let getUser = store.getters['auth/getUser']
    if(!getUser){
      store.dispatch('auth/NotFoundUser')
      next({
        path: '/login'
      })
      return
    }else{

    }
  }
  
  
  // if (to.matched.some(record => record.meta.requiresAdmin)) {
  //   let getUser = store.getters['auth/getUser']
  //   console.log(getUser)
  //   if(getUser.ROLE !=='Admin'){
  //     next({
  //       path: '/welcome'
  //     })
  //     return
  //   }else{

  //   }
      
  // }

  next() // make sure to always call next()!
  
})
export default router
