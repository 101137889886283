<template>
  <transition name="overlay">
    <div class="overlay-mask">
      <div  class="overlay-wrapper">
        <div class="overlay-container">

          <div >
            <a class="overlay-default-button h1" @click="$emit('close')">
                X
              </a>
            <slot name="header">
              
            </slot>
          </div>

          <div class="overlay-body">
            <slot name="body">
              <div class="d-flex  justify-content-center height-body">
                <div class="mx-md-auto">
                  <div class="row max-vw-80 mx-0 mt-5 pb-5 position-relative rounded-custom bg-gradient-red-orange">
                    <img class="logo-head-center" src="images/numchok.png" alt="">
                    <div class="col-12">
                      <div class="row card-white mt-4 m-1 m-md-5 rounded-custom">
                        <div class="col-12">
                          <p class="font-success text-center">ทำรายการสำเร็จ</p>
                        </div>
                        <div class="col-12">
                          <p class="font-overlay text-center">-- กรุณารอ sms จากระบบ --</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </slot>
          </div>

          <div >
            <slot name="footer">
              
              
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import * as serviceMain  from '@/services/main.service';
export default {
  name: 'Overlay'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scope>
.overlay-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.overlay-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.overlay-container {
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  margin: 0px 0px;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.overlay-header h3 {
  margin-top: 0;
  color: #42b983;
}

.overlay-body {
  margin: 20px 0;
}

.overlay-default-button {
  position: absolute;
  top: 15px;
  right: 15px;
  border: 5px gray solid;
  padding: 5px 12px;
  border-radius: 30px;
  
}

/*
 * The following styles are auto-applied to elements with
 * transition="overlay" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the overlay transition by editing
 * these styles.
 */

.overlay-enter {
  opacity: 0;
}

.overlay-leave-active {
  opacity: 0;
}

.overlay-enter .overlay-container,
.overlay-leave-active .overlay-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.height-body{
  min-height: 100vh;
 
}
.font-success{
  color: #71E367;
  font-size: 7vw;
}
.font-overlay{
  color: #787878;
  font-size: 4vw;
}
.card-white{
  background-color:white;
  
  padding: 150px 0px 100px 0px;
}
.rounded-custom{
  border-radius: 15px;
}
.logo-head-center{
  z-index: 99;
  width: clamp(50px, 50vw, 300px);
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%,-50%)
}
</style>
