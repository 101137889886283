<template>
  <router-view />
</template>
<script>


export default {
  name: 'App',
  components: {
   
  }
}
</script>

<style lang="scss">
@import 'https://fonts.googleapis.com/css?family=Kanit|Prompt';

*{
    font-family: 'Kanit', sans-serif;
}
.alias {cursor: alias;}
.all-scroll {cursor: all-scroll;}
.auto {cursor: auto;}
.cell {cursor: cell;}
.context-menu {cursor: context-menu;}
.col-resize {cursor: col-resize;}
.copy {cursor: copy;}
.crosshair {cursor: crosshair;}
.default {cursor: default;}
.e-resize {cursor: e-resize;}
.ew-resize {cursor: ew-resize;}
.grab {cursor: -webkit-grab; cursor: grab;}
.grabbing {cursor: -webkit-grabbing; cursor: grabbing;}
.help {cursor: help;}
.move {cursor: move;}
.n-resize {cursor: n-resize;}
.ne-resize {cursor: ne-resize;}
.nesw-resize {cursor: nesw-resize;}
.ns-resize {cursor: ns-resize;}
.nw-resize {cursor: nw-resize;}
.nwse-resize {cursor: nwse-resize;}
.no-drop {cursor: no-drop;}
.none {cursor: none;}
.not-allowed {cursor: not-allowed;}
.pointer {cursor: pointer;}
.progress {cursor: progress;}
.row-resize {cursor: row-resize;}
.s-resize {cursor: s-resize;}
.se-resize {cursor: se-resize;}
.sw-resize {cursor: sw-resize;}
.text {cursor: text;}
.w-resize {cursor: w-resize;}
.wait {cursor: wait;}
.zoom-in {cursor: zoom-in;}
.zoom-out {cursor: zoom-out;}
@for $i from 1 through 100 {
  $maxWidth: 1% * $i ;
  .max-w-#{$i} { 
      max-width: $maxWidth;
  }
  @media only screen and (max-width: 768px) {
  /* For tablets: */
    .max-w-#{$i} { 
        max-width: 100%;
    }
  }
}
@for $i from 1 through 100 {
  $maxWidth: 1vw * $i ;
  .max-vw-#{$i} { 
      max-width: $maxWidth;
  }
  @media only screen and (max-width: 768px) {
  /* For tablets: */
    .max-vw-#{$i} { 
        max-width: 100%;
    }
  }
}
.alert-green{
  background: #71E367 !important;
  border-radius: 20px !important;
}
.alert-red{
  background: #FF6262 !important;
  border-radius: 20px !important;
}
.set-center{
  margin:0px auto !important;
}
.content{
  padding: 70px 15px 15px 15px;
}
.box-popular{
    border: 3px #FFC700 solid;
    padding: 15px;
    border-radius: 8px;
}
.box-thin{
    border: 1px lightgray solid;
    padding: 15px;
}
.border-box-yellow{
  border: 3px #FFC700 solid;
  padding: 15px;
  border-radius: 8px;

}
.btn-tran{
  cursor: pointer;
  border: 3px solid #FFC700;
  background-color: white;
  border-radius: 8px;
  padding: 10px 15px;
}
.btn-tran.active{
  cursor: pointer;
  background-color: #FFC700;
}
.btn-fill{
  cursor: pointer;
  border: none;
  background-color: #FFC700;
  border-radius: 8px;
  padding: 13px 15px;
  color: black!important;
  font-weight: 600;
}

.input-tran{
  border: 3px solid #FFC700;
  background-color: white;
  border-radius: 8px;
  padding: 10px 15px;
}
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px #FFC700 solid;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #FFC700;
 
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.star {
  position: relative;
  
  display: inline-block;
  width: 0;
  height: 0;
  
  margin-left: .9em;
  margin-right: .9em;
  margin-bottom: 1.2em;
  
  border-right:  .3em solid transparent;
  border-bottom: .7em  solid #FC0;
  border-left:   .3em solid transparent;

  /* Controlls the size of the stars. */
  font-size: 24px;
  
  &:before, &:after {
    content: '';
    
    display: block;
    width: 0;
    height: 0;
    
    position: absolute;
    top: .6em;
    left: -1em;
  
    border-right:  1em solid transparent;
    border-bottom: .7em  solid #FC0;
    border-left:   1em solid transparent;
  
    transform: rotate(-35deg);
  }
  
  &:after {  
    transform: rotate(35deg);
  }
}
.bg-gradient-red-orange{
  background: rgb(170,10,10) !important;
  background: linear-gradient(180deg, rgba(170,10,10,1) 0%, rgba(219,158,80,1) 100%) !important;
}
.margin-for-logo-center{
  margin-top:50px
}
a{
  cursor: pointer;
}
.text-black{
  color: #000;
}
.btn-lotto{
  border:none;
}
.bg-yellow{
    background-color:#FFC700;
}
</style>
