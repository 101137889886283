import { createStore } from 'vuex'
import { display } from './display.module';
import { auth } from './auth.module';
import { cart } from './cart.module';
export default createStore({
  modules: {
    auth:auth,
    display:display,
    cart:cart
  }
})
