import {createI18n} from 'vue-i18n'
import en from '../translations/en.json'
import th from '../translations/th.json'
const messages = {
  en: en,
  th: th
}
const i18n = createI18n({
//   locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages //set locale messages
})
export default i18n