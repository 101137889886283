export async function call_api(method, url, data, auth) {
  return new Promise((resolve, reject) => {
    if (auth) {
      let gettoken = readCookie("TOKEN");
      $.ajaxSetup({
        headers: {
          Authorization: `Bearer ${gettoken}`,
        },
      });
    }
    $.ajax({
      type: method,
      url: url,
      data: JSON.stringify(data),
      dataType: "text",
      contentType: "application/json; charset=UTF-8",
      success: function (ret) {
        resolve(JSON.parse(ret));
      },
      error: function (e) {
        let err = JSON.parse(e.responseText);
        reject(new Error(err.error.message));
      },
    });
  });
}

export const Base64 = {
  encode: function (s) {
    return btoa(unescape(encodeURIComponent(s)));
  },
  decode: function (s) {
    return decodeURIComponent(escape(atob(s)));
  },
};

// create function for clear all cookie
export function clearAllCookies() {
  return new Promise(function (resolve, reject) {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }

    resolve();
  });
}

export function saveCookie(name, value, days) {
  var date = new Date();
  if (days) date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  //n days
  else date.setTime(date.getTime() + 10 * 60 * 60 * 1000); //106 hour

  var expires = "; expires=" + date.toUTCString();
  //	var expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}

export function readCookie(name) {
  try {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  } catch (error) {
    return null;
  }
}
export function readCookieEncrypt(name) {
  try {
    let read = Base64.decode(readCookie(name));
    return read;
  } catch (error) {
    return null;
  }
}
export function saveCookieEncrypt(name, value, days) {
  try {
    saveCookie(name, Base64.encode(value), days);
    return;
  } catch (error) {
    return null;
  }
}
export const parseJwtByLocalStorage = (token) => {
  try {
    let getDataToken = JSON.parse(
      atob(atob(atob(localStorage.getItem(token))).split(".")[1])
    );
    let getDate = Date.now();
    let getexp = getDataToken.exp * 1000;
    if (getDate >= getexp) {
      return null;
    }
    return getDataToken;
  } catch (e) {
    return null;
  }
};
export const parseJwtByCookie = (token) => {
  try {
    return JSON.parse(atob(readCookie(token).split(".")[1]));
  } catch (e) {
    return null;
  }
};
export function removeCookie(name) {
  saveCookie(name, "", -1);
}
export function showSuccessAlert(_this, para_message) {
  _this.$swal.fire({
    icon: "success",
    title: "Success",
    text: para_message,
    showConfirmButton: false,
    timer: 1500,
  });
}
export function showSuccessAlertConfirm(_this, para_message) {
  _this.$swal.fire({
    icon: "success",
    title: "Success",
    text: para_message,
  });
}
export function showErrorAlert(_this, error) {
  let getUL = "";
  try {
    if (error.validation) {
      getUL = error.validation.map((e) => `<li>${e.msg}</li>`).join("");
    }
  } catch (error) {
    console.log(error);
  }

  if (error.messageTH) {
    _this.$swal.fire({
      icon: "error",
      title: "Oops...",
      html: `
			<h3>${error.messageTH
        .replace("ER_SIGNAL_EXCEPTION:", "")
        .replace("ER_DATA_TOO_LONG:", "")}</h3>
			<ul>
			${getUL}
			</ul>
			
			`,
    });
  } else {
    _this.$swal.fire({
      icon: "error",
      title: "Oops...",
      html: `
			<h3>${error}</h3>
			<ul>
			${getUL}
			</ul>
			
			`,
    });
  }
}
export function Delay(time) {
  new Promise((resolve) => {
    setTimeout(() => {
      resolve("OK");
    }, time);
  });
}
// export function showErrorAlert(_this,para_message) {
// 	_this.$swal.fire({
// 	icon: "error",
// 	title: "Oops...",
// 	text: para_message.replace("ER_SIGNAL_EXCEPTION:",""),
//   });
// }
// async function logout(){
// 	try {

// 		let getUSER_ID = parseJwt('TOKEN').USER_ID;
// 		let data = {
// 				"USERID":getUSER_ID
// 			}

// 		let getapi = await call_api("POST", "../cmsapi/logout", data,'auth');
// 		removeCookie("TOKEN")
// 		window.location.href = "./login";

// 	} catch (error) {
// 		alert(error)
// 	}

// }
function copyToClipboard(id) {
  event.preventDefault();
  var from = document.getElementById(id);
  var range = document.createRange();
  window.getSelection().removeAllRanges();
  range.selectNode(from);
  window.getSelection().addRange(range);
  document.execCommand("copy");
  window.getSelection().removeAllRanges();
  Swal.fire({
    icon: "success",
    title: "คัดลอกแล้ว",
    showConfirmButton: false,
    timer: 1000,
  }).then(() => {});
}

// function readFile(_this,para_idImgSrc) {
// 	if (_this.files && _this.files[0]) {
// 	  var FR= new FileReader();
// 	  FR.addEventListener("load", function(e) {
// 		let getbase64 = e.target.result;
//         console.log("🚀 ~ file: maincms.js ~ line 107 ~ FR.addEventListener ~ getbase64", getbase64)
// 		document.getElementById(para_idImgSrc).src       = e.target.result;
// 		// document.getElementById("b64").innerHTML = e.target.result;
// 	  });
// 	  FR.readAsDataURL( _this.files[0] );
// 	}

//   }
// $( document ).ready(function() {
// 	let getimg = parseJwt('TOKEN').USER_IMG;
//     $(".img-nav-profile").attr("src",getimg);
// });
