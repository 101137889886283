import * as main from '@/services/main.service';
export const cart = {
  namespaced: true,
  state: {
    CART:main.readCookieEncrypt("RTCART"),
    
  },  
  getters: {
    getCART(state) {
      return state.CART ? 
      JSON.parse(state.CART):
      [];
    }
  },
  mutations: {
    setCART(state, CART) {
      state.CART = CART;
    },
  },
  actions: {
    setCART({commit},CART) {
      let EnCART = JSON.stringify(CART)
      // console.log(EnCART)
      main.saveCookieEncrypt("RTCART",EnCART )
      commit('setCART',EnCART)
    },
    addCART({commit},CART) {
      let getStateCart = state.CART
      getStateCart.push(CART)
      let EnCART = JSON.stringify(getStateCart)
      main.saveCookieEncrypt("RTCART",EnCART )
      commit('setCART',EnCART)
    },
  },
  
};
