// import AuthService from '@/services/auth.service';
import * as main from "@/services/main.service";
import * as constant from "@/services/constant";
const user = main.parseJwtByLocalStorage(constant.TOKEN_KEY);
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };
export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, val) {
      await main.clearAllCookies();
      let getEncodeAccess_token = btoa(btoa(val));
      localStorage.setItem("user", getEncodeAccess_token);
      let getUser = main.parseJwtByLocalStorage(constant.TOKEN_KEY);
      commit("loginSuccess", getUser);
    },
    async logout({ commit }) {
      localStorage.removeItem(constant.TOKEN_KEY);
      await main.clearAllCookies();

      commit("logout");
    },
    NotFoundUser({ commit }) {
      localStorage.removeItem(constant.TOKEN_KEY);
      commit("loginFailure");
    },
  },
  getters: {
    gettest(state) {
      return "asdasd";
    },
    getloggedIn(state) {
      return state.status.loggedIn;
    },
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
  },
};
