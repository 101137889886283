
export const display = {
  namespaced: true,
  state: {
    isAside: false,
    local:'en',
    navName:'',
    showOverlay:false
  },  
  getters: {
    getAside(state) {
      return state.isAside;
    },
    getLocal(state) {
      return state.local;
    },
    getNavName(state) {
      return state.navName;
    },
    getOverlay(state) {
      return state.showOverlay;
    }
  },
  mutations: {
    SET_Aside(state) {
      state.isAside =!state.isAside
    },
    SET_Local(state,local) {
      state.local = local
    },
    SET_NavName(state,navName) {
      state.navName = navName
    },
    SET_Overlay(state,showOverlay) {
      state.showOverlay = showOverlay
    }
  },
  actions: {
    toggle_Aside({ commit }) {
      commit("SET_Aside");
    },
    setLocal({ commit },local) {
      commit("SET_Local",local);
    },
    openOverlay({ commit }) {
      commit("SET_Overlay",true);
    },
    closeOverlay({ commit }) {
      commit("SET_Overlay",false);
    },
    setNavName({ commit },navName) {
      commit("SET_NavName",navName);
    },
  },
  modules: {
  }
};
